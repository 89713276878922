<template>
  <CCard>
    <CCardHeader>
      <slot name="header">
        <CIcon name="cil-grid"/> {{caption}}
      </slot>
    </CCardHeader>
    <CCardBody>
      <CDataTable
        hover
        striped
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="weekly_plans"
        :fields="fields"
        :items-per-page="small ? 20 : 8"
        :dark="dark"
        pagination
        table-filter
        items-per-page-select
        sorter
      >
        <template #from_date_plan="{item}">
          <td>
              {{formatDate(item.from_date_plan)}}
          </td>     
        </template>
        <template #to_date_plan="{item}">
          <td>
              {{formatDate(item.to_date_plan)}}
          </td>     
        </template>
        <template #action="{item}">
          <td>
             <button class="btn btn-sm btn-outline-secondary" @click.prevent="updatePlan(item)">
                <i class="bi bi-pen text-primary"></i>
             </button>
          </td>
        </template>
      </CDataTable>

    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'Table',
  props: {
    weekly_plans: Array,
    fields: {
      type: Array,
      default () {
        return ['plan','action']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: Boolean,
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {

    formatDate(date) {
      return moment
        .utc(date, "YYYY-MM-D")
        .locale("en-us")
        .local()
        .format("MMM D YYYY");
    },
   updatePlan(item) {
      this.$emit('updatePlan', item);
    },

  }
}
</script>
